import React                                   from 'react';
import Img                                     from 'gatsby-image';
import { useStaticQuery, graphql }             from 'gatsby';
import { Center, Box, useMediaQuery, Heading } from '@chakra-ui/react';

import Spacer  from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Mounted from '@interness/web-core/src/components/structure/Mounted/Mounted';

import RolexLayout   from '../../../components/Layout';
import Checkboard    from '../../../components/Checkboard';
import Tracking      from '../../../components/Tracking';
import KeepExploring from '../../../components/KeepExploring';
import TwoColumn     from '../../../components/TwoColumn';

const RolexGeschichtePage = () => {
  const data = useStaticQuery(query);
  const [portrait] = useMediaQuery('(max-width: 768px)');
  const name = data.directusCompany.display_name;

  const media = {
    banner: {
      portrait: data.bannerPortrait.childImageSharp.fluid,
      landscape: data.bannerLandscape.childImageSharp.fluid,
    },
    tradition: {
      portrait: data.traditionPortrait.childImageSharp.fluid,
      landscape: data.traditionLandscape.childImageSharp.fluid,
    },
    heute: {
      portrait: data.heutePortrait.childImageSharp.fluid,
      landscape: data.heuteLandscape.childImageSharp.fluid,
    },
    '1901': {
      portrait: data['i1901Portrait'].childImageSharp.fluid,
      landscape: data['i1901Landscape'].childImageSharp.fluid,
    },
    '1956': {
      portrait: data['i1956Portrait'].childImageSharp.fluid,
      landscape: data['i1956Landscape'].childImageSharp.fluid,
    },
    '2005': {
      portrait: data['i2005Portrait'].childImageSharp.fluid,
      landscape: data['i2005Landscape'].childImageSharp.fluid,
    },
  }
  return (
    <RolexLayout>
      <Mounted>
        <Tracking pageType={'history page'}/>
        <section>
          <Img width="100%" height="25vh"
               fluid={portrait ? media.banner.portrait : media.banner.landscape}/>
        </section>
        <Spacer/>
        <section>
          <Center width="90%" margin="auto">
            <Box maxW="650px" w="100%" textAlign="center">
              <p><b>{name} und Rolex</b></p>
              <Box sx={{
                letterSpacing: '4.5px',
                fontWeight: '300',
                lineHeight: '1.2',
              }}>
                <Heading as="h1" size="lg" sx={{ hyphens: 'auto' }}>UNSERE GESCHICHTE</Heading>
              </Box>
              <p></p>
            </Box>
          </Center>
        </section>
        <Spacer/>
        <section>
          <Checkboard title={'DER TRADITIONSJUWELIER'} imageSet={media.tradition}>
            Juwelier Hohage aus Lüdenscheid steht - seit mehr als einem Jahrhundert - für Qualität, Kundenorientierung
            und umfassende Service Leistungen der hauseigenen Meisterwerkstätte. Das Geschäft präsentiert sich in einem
            exklusiven und zeitgemäßen Ambiente mit einer großen Auswahl an aus- gewählten Zeitmessern und aktuellen
            Schmuckkollektionen.
          </Checkboard>
          <Checkboard title={'1901'} imageSet={media['1901']} inverted>
            Juwelier Hohage beweist gekonnt, dass Tradition und Moderne Hand in Hand gehen. Die Geschichte des
            Lüdenscheider Top-Juweliers, nahm mit dem Uhrmachermeister Albert Hohage im Jahr 1901 ihren Anfang und
            besticht heute wie damals durch ein überaus hohes Maß an Perfektion und Qualität.
            Ein unermesslicher Erfahrungsschatz und das Wissen um die Wünsche der Kunden werden von Generation zu
            Generation in die Zukunft getragen.
          </Checkboard>
          <Checkboard title={'1956'} imageSet={media['1956']}>
            Im Jahr 1956 begann die Partnerschaft mit Rolex die mit viel Engagement und neuen Innovationen in der
            Zukunft
            fortgeführt wird.
            Eine perfekte Beratung beim Kauf einer neuen Rolex Uhr
            oder bei der Revision Ihres bisherigen Modells – Juwelier Hohage ist mit der Rolex zertifizierten
            Uhrmacherwerk- statt für alle notwendigen Instandhaltungs- sowie Reparaturmaßnahmen direkt im Haus für Sie
            da.
          </Checkboard>
          <Checkboard title={'2005'} imageSet={media['2005']} inverted>
            Seit 2005 wird das renommierte Geschäft von der Familie Markes geführt und hat weit über die lokale
            Bedeutung Geltung und Wertschätzung erfahren.
          </Checkboard>
          <TwoColumn title={'Heute'} imageSet={media['heute']}>
            Die Kombination aus erlesener Auswahl von Uhren-, Schmuck- und Trauringkollektionen, sowie ein meisterhafter
            Service und eine besondere Kundenorientierung zählen zu den Erfolgsgeheimnissen von Juwelier Hohage.
            So hat das Geschäft nicht nur eine herausragende Bedeutung im Märkischen Kreis, sondern ist auch in der
            Online-Welt auf vielen Plattformen aktiv.
          </TwoColumn>
        </section>
        <Spacer/>
        <KeepExploring/>
        <Spacer/>
      </Mounted>
    </RolexLayout>
  )
};

export default RolexGeschichtePage;

const query = graphql`
    query {
        directusCompany {
            display_name
        }
        bannerLandscape: file(name: {eq: "banner_landscape_02"} relativeDirectory: {eq: "landscape"}) {
            name
            childImageSharp {
                fluid(quality: 100, maxWidth: 2880) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        bannerPortrait: file(name: {eq: "banner_portrait_02"} relativeDirectory: {eq: "portrait"}) {
            name
            childImageSharp {
                fluid(quality: 100, maxWidth: 750) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        traditionLandscape: file(name: {eq: "tradition"} relativeDirectory: {eq: "landscape/history"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        traditionPortrait: file(name: {eq: "tradition"} relativeDirectory: {eq: "portrait/history"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        heuteLandscape: file(name: {eq: "heute"} relativeDirectory: {eq: "landscape/history"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        heutePortrait: file(name: {eq: "heute"} relativeDirectory: {eq: "portrait/history"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        i1901Landscape: file(name: {eq: "1901"} relativeDirectory: {eq: "landscape/history"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        i1901Portrait: file(name: {eq: "1901"} relativeDirectory: {eq: "portrait/history"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        i1956Landscape: file(name: {eq: "1956"} relativeDirectory: {eq: "landscape/history"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        i1956Portrait: file(name: {eq: "1956"} relativeDirectory: {eq: "portrait/history"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        i2005Landscape: file(name: {eq: "2005"} relativeDirectory: {eq: "landscape/history"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        i2005Portrait: file(name: {eq: "2005"} relativeDirectory: {eq: "portrait/history"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;